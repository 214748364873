<template>
  <div class="appUpdateContent">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>应用更新</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button
      size="small"
      type="primary"
      style="float: right; margin: 5px 10px 10px 0px"
      @click="toAddApp"
    >
      添加应用
    </el-button>
    <el-table
      ref="showTableData"
      :data="showTableData"
      border
      style="width: calc(100vw - 240px)"
    >
      <el-table-column label="更新类型" prop="type" width="150px">
        <template slot-scope="scope">
          <span>{{ updateType.get(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="id" prop="id" width="">
        <!-- <template slot-scope="scope">
            <div>
              {{ createTimeRender(scope.row.createTime) }}
            
            </div>
          </template> -->
      </el-table-column>
      <el-table-column label="下载地址" prop="downloadUrl" width="150">
      </el-table-column>
      <el-table-column label="版本描述" prop="versionDescription" width="150">
      </el-table-column>
      <el-table-column label="版本号" prop="versionNumber" width="">
      </el-table-column>
      <el-table-column label="更新code" prop="code" width=""> </el-table-column>

      <el-table-column label="版本终端类型" prop="versionTerminal" width="">
        <!-- <template slot-scope="scope">
            <div>
              {{ ["未开播", "正在直播", "直播结束"][scope.row.status] }}
            </div>
          </template> -->
      </el-table-column>

      <el-table-column label="版本更新时间" prop="versionUpdateDataTime">
        <template slot-scope="scope">
          <div v-if="scope.row.versionUpdateDataTime">
            {{ versionUpdateTime(scope.row.versionUpdateDataTime) }}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon="el-icon-info"
            icon-color="red"
            title="确定删除此应用吗？"
            @confirm="toDeleteApp(scope.row)"
          >
            <el-button type="text" slot="reference"> 删除应用 </el-button>
          </el-popconfirm>

          <!-- &nbsp;&nbsp;
            <el-button type="text" @click="toViewViolation(scope.row)">
              查看违规
            </el-button>
            &nbsp;&nbsp;
            <el-button type="text" @click="toViewDetail(scope.row)">
              查看详情
            </el-button>
            &nbsp;&nbsp;
            <el-button type="text" @click="toSendBlessingBag(scope.row)">
              发起福袋
            </el-button>
            &nbsp;&nbsp;
            <el-button type="text" @click="toViewBlessingBagDetail(scope.row)">
              福袋详情
            </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="queryInfo.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="queryInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableOtherInfo.total"
    >
    </el-pagination>

    <el-dialog
      :close-on-click-modal="false"
      title="添加应用"
      class="dialogApplication"
      :visible.sync="applicationVisible"
      :destroy-on-close="true"
      append-to-body
    >
      <el-form
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        label-position="right"
        label-width="120px"
        size="small"
        enctype="multipart/form-data"
      >
        <el-form-item label="更新code" prop="code">
          <el-input v-model="dataForm.code" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="更新类型" prop="type">
          <!-- <el-input
            v-model="dataForm.type"
            placeholder="请输入"
            disabled
          ></el-input> -->
          <el-select v-model="dataForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本描述" prop="versionDescription">
          <!-- <el-input
            v-model="dataForm.versionDescription"
            placeholder="请输入"
          ></el-input> -->
          <el-input
            type="textarea"
            autosize
            placeholder="请输入"
            v-model="dataForm.versionDescription"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="版本号" prop="versionNumber">
          <el-input
            v-model="dataForm.versionNumber"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="	版本终端类型" prop="versionTerminal">
          <!-- <el-input
            v-model="dataForm.versionTerminal"
            placeholder="请输入"
          ></el-input> -->
          <el-select v-model="dataForm.versionTerminal" placeholder="请选择">
            <el-option
              v-for="item in versionTerminalOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本更新时间" prop="versionUpdateDataTime">
          <el-input
            v-model="dataForm.versionUpdateDataTime"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传应用" prop="downloadUrl" style="width: 600px">
          <el-upload
            action=""
            :http-request="uploadFiles"
            multiple
            :limit="1"
            ref="upload"
            :on-remove="handleRemove"
            v-loading="dataForm.loadFile"
          >
            <!-- :file-list="fileList" -->
            <!-- :loading="dataForm.loadFile" -->

            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applicationVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="finishAddApplication"
          :loading="dialogLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  searchVersionList,
  insertVersion,
  delVersion,
  uploadFiles,
} from "@/api";
import { formatTime2 } from "../../utils/utils";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      typeOptions: [
        { label: "用户端", value: 0 },
        { label: "商家端", value: 1 },
        { label: "其他", value: 3 },
      ],
      versionTerminalOptions: [
        { label: "安卓", value: "安卓" },
        { label: "ios", value: "ios" },
      ],
      fileList: [],
      dialogLoading: false,
      dataForm: {
        code: "",
        type: "",
        versionDescription: "",
        versionNumber: "",
        versionTerminal: "",
        versionUpdateDataTime: "",
        downloadUrl: "",
        loadFile: false,
      },

      applicationVisible: false,
      updateType: new Map([
        [0, "app"],
        [1, "商家端"],
        [3, "其他"],
      ]),
      queryInfo: {
        page: 1,
        size: 10,
        order: "desc",
        // keyword: "",
        // userName: "",
        // roomName: "",
        // businessId: 0,
      },
      showTableData: [],
      tableData: [],
      tableOtherInfo: { total: 0 },
      rules: {
        type: [{ required: true, message: "请输入更新类型" }],
        versionNumber: [{ required: true, message: "请输入版本号" }],
        versionTerminal: [{ required: true, message: "请输入版本终端类型" }],
        downloadUrl: [{ required: true, message: "请上传应用" }],
        versionDescription: [{ required: true, message: "请输入版本描述" }],
        code: [{ required: true, message: "请输入更新code" }],
      },
    };
  },
  created() {
    this.getVersionList();
  },
  watch: {
    value: {
      handler(newValue, oldValue) {},
    },
  },
  methods: {
    versionUpdateTime(time) {
      return formatTime2(time);
    },
    handleRemove() {
      this.dataForm.downloadUrl = "";
    },

    async uploadFiles(file) {
      console.log(file, "file");
      try {
        this.dataForm.loadFile = true;

        const formData = new FormData();
        formData.append("file", file.file);
        const res = await uploadFiles(formData);
        if (res.data.code === 200) {
          this.dataForm.downloadUrl = res.data.body;
          this.dataForm.loadFile = false;
          this.$refs.dataForm.validateField("downloadUrl");
        } else {
          this.$message.error(res.data.msg);
          this.dataForm.loadFile = false;
        }
      } catch (error) {
        this.$message.error("上传失败");
        this.dataForm.loadFile = false;
      }
    },
    async finishAddApplication() {
      this.dialogLoading = true;
      this.$refs["dataForm"].validate(async (valid, fields) => {
        if (valid) {
          const params = { ...this.dataForm };
          delete params.loadFile;
          const res = await insertVersion(params);
          if (res.data.code === 200) {
            this.$message.success("上传成功");

            this.applicationVisible = false;
            this.dialogLoading = false;

            this.getVersionList();
            this.dataForm = {
              code: "",
              type: "",
              versionDescription: "",
              versionNumber: "",
              versionTerminal: "",
              versionUpdateDataTime: "",
              downloadUrl: "",
              loadFile: false,
            };
          } else {
            this.$message.error(res.data.msg);
            this.dialogLoading = false;
          }
        } else {
          console.log("error submit!", fields);
          this.dialogLoading = false;
        }
      });
    },
    async toDeleteApp(item) {
      const formData = new FormData();
      formData.append("ids", [item.id]);
      const res = await delVersion(formData);
      this.$message.success("删除成功");
      this.getVersionList();
    },
    toAddApp(item) {
      this.applicationVisible = true;
    },
    _handleSizeChange(newSize) {
      this.queryInfo.size = newSize;
      // if (this.tableData.length !== 0) {
      //   this.showTableData = this.tableData.slice(0, newSize);
      // }
      this.queryInfo.page = 1;
      this.getVersionList();

      // this.getShoppingList();
      // console.log(`每页 ${newSize} 条`);
    },

    _handleCurrentChange(data) {
      this.queryInfo.page = data;
      // if (this.tableData.length !== 0) {
      // if(this.queryInfo.size === 10 ){
      // const size = this.queryInfo.size;
      this.getVersionList();
      // this.showTableData = this.tableData.slice(
      //   (val - 1) * size,
      //   (val - 1) * size + size
      // );
      // }
      // }
    },
    async getVersionList() {
      const { data: res } = await searchVersionList(this.queryInfo);
      this.tableData = res.body?.list;
      this.showTableData = res.body?.list;
      // res.body.list.slice(0, 10);
      this.tableOtherInfo.total = res.body.total;
    },
  },
};
</script>
<style scoped>
.appUpdateContent {
  background-color: #fff;
  padding: 15px 15px;
}
</style>
